var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { align: "left", justify: "left" } },
    [
      _c(
        "v-card-text",
        { staticClass: "pa-3 font-weight-light white black--text" },
        [
          _c("v-icon", { staticClass: "primary--text lighten-2 pa-2" }, [
            _vm._v(" mdi-sync ")
          ]),
          _vm._v(_vm._s(_vm.title) + " ")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }